import { useUserSettingsContext } from '@app/UserSettingsProvider';
import { Icon, IconSize } from '@assets/icon';
import { InvoiceActionDropdownMenuView } from '@components/contracts/invoices/actionDropdownMenuButton/InvoiceActionDropdownMenuView';
import { getEntityName } from '@components/contracts/invoices/helpers/getEntityName';
import { useDocumentPermissionInfo } from '@hooks/documentPermissions/useDocumentPermissionInfo';
import { useTenantInfo } from '@hooks/useTenantInfo';
import { CustomButton } from '@ui/button/Button';
import { Card, Col, QRCode, Row, Typography } from 'antd';
import { CardProps } from 'antd/lib/card/Card';
import { ReactNode, forwardRef } from 'react';
import './InvoiceCard.scss';

interface InvoiceCardProps extends CardProps {
  children?: ReactNode;
  title: string;
  date?: Date | string;
  dueDate?: Date | string;
  withActions: boolean;
  onPrint?: () => void;
  onDownload?: () => void;
  onSend?: () => void;
  copy?: boolean;
  entityType?: 'request' | 'invoice' | 'repair ticket' | 'estimate';
  orderId?: string;
}

export type Ref = HTMLDivElement | null;
export const InvoiceCard = forwardRef<Ref, InvoiceCardProps>(
  (
    {
      onDownload,
      onPrint,
      onSend,
      children,
      date = '',
      dueDate,
      withActions,
      title,
      copy,
      entityType = 'invoice',
      orderId,
      ...props
    },
    ref,
  ) => {
    const { mainLogo } = useTenantInfo();
    const {
      isInvoiceCreationDateEnabled,
      isPurchaseCreationDateEnabled,
      isEstimateCreationDateEnabled,
    } = useDocumentPermissionInfo();
    const { formatDateAndTime } = useUserSettingsContext();

    const entityName = getEntityName(entityType);
    const isDateVisible =
      entityType === 'request'
        ? isPurchaseCreationDateEnabled
        : entityType === 'invoice'
        ? isInvoiceCreationDateEnabled
        : entityType === 'estimate'
        ? isEstimateCreationDateEnabled
        : true;

    return (
      <Row style={{ padding: 0, marginTop: 15 }}>
        <Col span={16}>
          <Card
            style={{ padding: '15px 0' }}
            styles={{
              body: {
                padding: 0,
              },
            }}
            ref={ref}
            {...props}
          >
            <Row
              style={{
                padding: 20,
                borderBottom: '1px #E7E7E7 solid',
                margin: 0,
              }}
              justify={'space-between'}
              gutter={24}
            >
              <Row
                justify={'space-between'}
                style={{ gap: 16 }}
                align={'middle'}
              >
                {mainLogo && (
                  <img
                    src={mainLogo}
                    alt={'Logo'}
                    width={72}
                    height={72}
                    style={{ objectFit: 'contain' }}
                  />
                )}
                <Col>
                  <Row>
                    <Typography.Title
                      level={4}
                      style={{
                        fontWeight: 'bold',
                        fontSize: 24,
                      }}
                    >
                      {`${entityName} No.`}
                    </Typography.Title>
                    <Typography.Title
                      level={4}
                      style={{
                        fontWeight: 600,
                        fontSize: 24,
                      }}
                      copyable={
                        copy && {
                          icon: [<Icon type={'copy'} key={'copy-icon'} />],
                          tooltips: ['copy', 'copied!'],
                        }
                      }
                    >
                      {title}
                    </Typography.Title>
                  </Row>

                  {orderId && (
                    <Row>
                      <Typography.Text
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {`Order No.${orderId}`}
                      </Typography.Text>
                    </Row>
                  )}
                  {isDateVisible && (
                    <>
                      <Row>
                        <Typography.Text
                          style={{
                            fontSize: 14,
                          }}
                        >
                          {`${entityName} Creation Date ${formatDateAndTime(
                            date,
                          )}`}
                        </Typography.Text>
                      </Row>
                      {!!dueDate && (
                        <Row>
                          <Typography.Text
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {`${entityName} Due Date ${formatDateAndTime(
                              dueDate,
                            )}`}
                          </Typography.Text>
                        </Row>
                      )}
                    </>
                  )}
                </Col>
              </Row>

              {entityType === 'invoice' && (
                <Row>
                  <Col>
                    <QRCode
                      value={title || '-'}
                      size={84}
                      bordered={false}
                      style={{
                        marginTop: -4,
                        marginRight: -4,
                      }}
                    />
                  </Col>
                </Row>
              )}
              {withActions && (
                <Row
                  align={'middle'}
                  justify={'space-between'}
                  className={'print'}
                >
                  <CustomButton
                    icon={<Icon type={'print'} size={IconSize.Large} />}
                    type={'text'}
                    size={'large'}
                    onClick={onPrint}
                  />

                  <InvoiceActionDropdownMenuView
                    onDownload={onDownload}
                    onSend={onSend}
                  />
                </Row>
              )}
            </Row>
            {children}
          </Card>
        </Col>
      </Row>
    );
  },
);
