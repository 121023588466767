import { FC, useState } from 'react';

import { TreeSelect } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

import { useChartTreeSelectQuery } from '@/graphql';
import { TreeSelectProps } from 'antd/lib';

interface Props extends TreeSelectProps {
  onChange?: (value: string) => void;
  type?: 'income' | 'cost' | 'assets';
}

const DealTransactionTreeSelect: FC<Props> = ({ onChange, value, type }) => {
  const [treeData, setTreeData] = useState<Omit<DefaultOptionType, 'label'>[]>(
    [],
  );

  const chartTreeQuery = useChartTreeSelectQuery({
    variables: {
      filtersChartAccount: {
        name: {
          notIn: ['Gross Profit', 'Pre Tax Income', 'Net Income'],
        },
        type: {
          eq: type,
        }
      },
    },
    onCompleted: (result) => {
      const tree = (result?.chartAccounts?.data || []).map((account) => {
        const accountId = account?.id;
        const accountName = account?.attributes?.name;

        const categoriesForAccount = (
          result?.chartCategories?.data || []
        ).filter(
          (filteredCategory) =>
            filteredCategory?.attributes?.chartAccount?.data?.id === accountId,
        );

        const mappedCategories = categoriesForAccount.map((mappedCategory) => {
          const categoryId = mappedCategory?.id;
          const categoryName = mappedCategory?.attributes?.name;

          const subcategoriesForCategory = (
            result?.chartSubcategories?.data || []
          ).filter(
            (filteredSubcategory) =>
              filteredSubcategory?.attributes?.chartCategory?.data?.id ===
              categoryId,
          );

          const mappedSubcategories = subcategoriesForCategory.map(
            (mappedSubcategory) => ({
              value: `${accountId}-${categoryId}-${mappedSubcategory?.id}`,
              title: mappedSubcategory?.attributes?.name,
              isLeaf: true,
            }),
          );

          return {
            value: `${accountId}-${categoryId}-0`,
            title: categoryName,
            children: mappedSubcategories,
          };
        });

        return {
          value: `${accountId}-0-0`,
          title: accountName,
          children: mappedCategories,
        };
      });

      setTreeData(tree);
    },
  });

  return (
    <TreeSelect
      showSearch
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={'Please select'}
      allowClear
      value={value}
      onChange={onChange}
      filterTreeNode={(input, data) =>
        (data.title as string).toLowerCase().includes(input)
      }
      treeData={treeData}
    />
  );
};

export default DealTransactionTreeSelect;
