import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import AuthLayout from '@components/layout/AuthLayout';
import DefaultLayout, { Loader } from '@components/layout/MainLayout';
import OnboardingLayout from '@components/layout/OnboardingLayout';
import TenantWrapper from '@components/tenant/TenantWrapper';

import { accountingRoutes } from '@pages/accounting/routes';
import authRoutes from '@pages/auth/routes';
import { CRMRoutes } from '@pages/crm/routes';
import { dashboardRoutes } from '@pages/dashboard/routes';
import { hrRoutes } from '@pages/hr/routes';
import { inventoryRoutes } from '@pages/inventory/routes';
import { marketingRoutes } from '@pages/marketing/routes';
// import { quotesRoutes } from '@pages/quotes/routes';
import { sellingRoutes } from '@pages/selling/routes';
import { settingsRoutes } from '@pages/settings/routes';
// import { websiteRoutes } from '@pages/websiteEditor/routes';
import { ROUTES } from '@router/routes';

import PlatformRoutes from '@auth/PlatformRoutes';

import { contractsRoutes } from '@pages/contracts/routes';
import { locationRoutes } from '@pages/location/routes';
import { messagingRoutes } from '@pages/messaging/routes';
import { reportsRoute } from '@pages/reports/routes';
// import { schedulingRoutes } from '@pages/scheduling/routes';
import { downloadsRoute } from './downloads/routes';
import paymentRoutes from './payment/routes';

import { tasksRoute } from './tasks/routes';

import PrivacyPolicy from '@pages/privacyPolicy';
import ReviewPage from '@pages/review';
import Terms from '@pages/terms';

const FillForm = lazy<FC>(() => import('@pages/fill-form'));
const SignContract = lazy<FC>(() => import('@pages/sign-contract'));
const FillProfile = lazy<FC>(() => import('@/pages/fill-profile'));
const ErrorPage = lazy<FC>(() => import('@/pages/errorPage'));
const Unsubscribe = lazy<FC>(() => import('@pages/unsubscribe/unsubscribe'));
const Subscribed = lazy<FC>(() => import('@pages/subscribe/subscribe'));
const NylasAuth = lazy<FC>(() => import('@pages/settings/nylasAuth'));
const WoocommerceAuth = lazy<FC>(
  () => import('@pages/settings/woocommerce-auth'),
);
const MagentoAuth = lazy<FC>(() => import('@pages/settings/magento-auth'));

const PreviewPage = lazy<FC>(
  () => import('@pages/websiteEditor/preview-page/PreviewPage'),
);
const OnboardingPage = lazy<FC>(() => import('@pages/onboarding'));

const XeroAuthCallback = lazy<FC>(
  () => import('@pages/settings/xero-auth-callback')
);

const QuickbooksAuth = lazy<FC>(
  () => import('@pages/settings/quickbooksAuth')
);

const QuickBooksProductMapping = lazy<FC>(
  () => import('@/pages/settings/accountingService/quickbookProductMapping')
);

const XeroProductMapping = lazy<FC>(
  () => import('@/pages/settings/accountingService/xeroProductMapping')
);

const tenantRoutes = ROUTES.tenant;
const globalRoutes = ROUTES.global;

// TODO: Valentyn V. - return temporary hidden features/modules
const routes: RouteObject[] = [
  {
    Component: PlatformRoutes,
    children: [
      {
        path: ':tenantSlug?',
        Component: TenantWrapper,
        children: [
          {
            Component: OnboardingLayout,
            loader: () => <Loader spinning />,
            children: [
              {
                element: <Navigate to={tenantRoutes.onboarding} />,
                index: true,
              },
              {
                Component: OnboardingPage,
                path: tenantRoutes.onboarding,
              },
            ],
          },
          {
            Component: DefaultLayout,
            loader: () => <Loader spinning />,
            children: [
              {
                element: <Navigate to={tenantRoutes.dashboard.index} />,
                index: true,
              },
              dashboardRoutes,
              CRMRoutes,
              inventoryRoutes,
              sellingRoutes,
              settingsRoutes,
              accountingRoutes,
              // websiteRoutes,
              hrRoutes,
              tasksRoute,
              // schedulingRoutes,
              downloadsRoute,
              marketingRoutes,
              // quotesRoutes,
              reportsRoute,
              contractsRoutes,
              locationRoutes,
              messagingRoutes,
              {
                Component: ErrorPage,
                path: '*',
              },
              {
                Component: PreviewPage,
                path: `${ROUTES.tenant.preview}/:page`,
              },
              {
                Component: QuickBooksProductMapping,
                path: tenantRoutes.quickbooksProductMapping,
              },
              {
                Component: XeroProductMapping,
                path: tenantRoutes.xeroProductMapping,
              }
            ],
          },
        ],
      },
      {
        Component: ErrorPage,
        path: ROUTES.tenant.notFound,
      },
      {
        Component: ErrorPage,
        path: '*',
      },
    ],
  },
  authRoutes,
  paymentRoutes,
  {
    Component: AuthLayout,
    path: globalRoutes.fillProfile,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: FillProfile,
        index: true,
      },
    ],
  },
  {
    Component: Unsubscribe,
    path: 'unsubscribe/:token',
    loader: () => <Loader spinning />,
  },
  {
    Component: Subscribed,
    path: globalRoutes.subscribe,
    loader: () => <Loader spinning />,
  },
  {
    Component: NylasAuth,
    path: globalRoutes.nylas,
    loader: () => <Loader spinning />,
  },
  {
    Component: WoocommerceAuth,
    path: globalRoutes.woocommerce,
  },
  {
    Component: MagentoAuth,
    path: globalRoutes.magento,
  },
  {
    Component: SignContract,
    path: globalRoutes.signContract,
    loader: () => <Loader spinning />,
  },
  {
    Component: FillForm,
    path: globalRoutes.fillForm,
    loader: () => <Loader spinning />,
  },
  {
    Component: ReviewPage,
    path: globalRoutes.review,
    loader: () => <Loader spinning />,
  },
  {
    Component: Terms,
    path: globalRoutes.terms,
    loader: () => <Loader spinning />,
  },
  {
    Component: PrivacyPolicy,
    path: globalRoutes.privacyPolicy,
    loader: () => <Loader spinning />,
  },
  {
    Component: XeroAuthCallback,
    path: globalRoutes.xero,
    loader: () => <Loader spinning />,
  },
  {
    Component: QuickbooksAuth,
    path: globalRoutes.quickbooks,
    loader: () => <Loader spinning />,
  },
  {
    Component: ErrorPage,
    path: '*',
  },
];

export default routes;
