import { useOrdersQuery } from '@/graphql';
import { SearchSelect } from '@form/inputs/searchSelect/SearchSelect';
import { SelectProps } from 'antd/lib';
import { FC, useEffect, useMemo } from 'react';
import { useOrderIdDataSelect } from './useOrderIdDataSelect';

interface Props extends SelectProps {
  onChange?: (value: string | string[]) => void;
  isMultiple?: boolean;
}

export const OrderIdSelect: FC<Props> = ({
  onChange,
  value,
  isMultiple = false,
}) => {
  const { handleChange, handleSearch, queryParams, searchText, setSearchText } =
    useOrderIdDataSelect({
      onChange,
    });

  const { data, refetch, loading } = useOrdersQuery({
    variables: queryParams,
  });

  useEffect(() => {
    refetch();
  }, [queryParams, refetch]);

  const options = useMemo(() => {
    const ordersData = data?.orders?.data || [];
    const transformedData = ordersData?.map((order) => ({
      value: order?.id,
      label: order?.attributes?.orderId,
    }));
    return transformedData;
  }, [data?.orders?.data]);

  useEffect(() => {
    if (options.length === 1 && options[0].value && onChange && isMultiple) {
      const newValue = options[0].value as string;
      const currentValues = Array.isArray(value) ? value : value ? [value] : [];
      if (!currentValues.includes(newValue)) {
        onChange([...currentValues, newValue]);
        setSearchText('');
      }
    }
  }, [options, onChange, isMultiple, value, setSearchText]);

  return (
    <SearchSelect
      optionLabelProp={'label'}
      placeholder={'Select the order'}
      onChange={handleChange}
      onSearch={handleSearch}
      options={options}
      value={value}
      loading={loading}
      mode={isMultiple ? 'multiple' : undefined}
      searchValue={searchText}
    />
  );
};
